import Vue from 'vue';

import { authHeader } from '../_helpers';

export const rodoviaService = {
  find,
  findBySearch,
  findRodoviaSegmento,
  findRodoviaTrecho,
  getById,
  save,
  delete: _delete
};

function find(siglaEmpresa) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/rodovias/${siglaEmpresa}/all`, requestCfg);
}

function findBySearch(siglaEmpresa, search) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/rodovias/${siglaEmpresa}/all?search=${search}`, requestCfg);
}

function findRodoviaSegmento(nomeSegmento) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/rodovias/${nomeSegmento}/segmentos`, requestCfg);
}

function findRodoviaTrecho() {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/rodovias/trechos`, requestCfg);
}

function getById(idRodovia) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/rodovias/${idRodovia}`, requestCfg);
}

function save(rodovia) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.post(`/rodovias`, rodovia, requestCfg);
}

function _delete(idRodovia) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.delete(`/rodovias/${idRodovia}`, requestCfg);
}
