import Vue from 'vue';

import { authHeader } from '../_helpers';

export const notificacaoService = {
  findNotificacao,
  getNotificacaoById,
  downloadAnexo,
  exportXls
};

function findNotificacao(siglaEmpresa, search, subtipo, dataInicial, dataFinal, rodoviasId) {
  let parms = '';
  if (search) {
    parms += 'search=' + search;
  }

  if (subtipo != null) {
    if (parms != '') parms += '&';
    parms += 'tipo=' + subtipo;
  }

  if (dataInicial != null) {
    if (parms != '') parms += '&';
    parms += 'dataInicial=' + dataInicial;
  }

  if (dataFinal != null) {
    if (parms != '') parms += '&';
    parms += 'dataFinal=' + dataFinal;
  }

  if (rodoviasId != null) {
    if (parms != '') parms += '&';
    parms += 'rodovias=' + rodoviasId;
  }

  if (parms != '') parms = '?' + parms;

  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/notificacoes/${siglaEmpresa}${parms}`, requestCfg);
}

function getNotificacaoById(siglaEmpresa, idNotificacao) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/notificacoes/${siglaEmpresa}/${idNotificacao}`, requestCfg);
}

function downloadAnexo(siglaEmpresa, modulo, uuid) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'arraybuffer'
  };

  return Vue.axios.get(`/downloads/${siglaEmpresa}/anexos/${modulo}/${uuid}`, requestCfg);
}

function exportXls(siglaEmpresa, search, subtipo, dataInicial, dataFinal, rodovia) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader(),
    responseType: 'arraybuffer'
  };

  let parms = '';
  if (search) {
    parms += 'search=' + search;
  }

  if (subtipo != null) {
    if (parms != '') parms += '&';
    parms += 'subtipo=' + subtipo;
  }

  if (dataInicial != null) {
    if (parms != '') parms += '&';
    parms += 'dataInicial=' + dataInicial;
  }

  if (dataFinal != null) {
    if (parms != '') parms += '&';
    parms += 'dataFinal=' + dataFinal;
  }

  if (rodovia != null) {
    if (parms != '') parms += '&';
    parms += 'rodovia=' + rodovia;
  }

  if (parms != '') parms = '?' + parms;

  return Vue.axios.get(`/notificacoes/${siglaEmpresa}/exportacao${parms}`, requestCfg);
}
