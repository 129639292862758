<template>
  <div class="page-header clear-filter" filter-color="green">
    <div class="page-header-image" :style="backgroundLogin"></div>
    <div class="content">
      <form>
        <div class="container">
          <div class="col-md-5 ml-auto mr-auto">
            <card type="login" plain>
              <div slot="header" class="content-top brand">
                <h2 class="h1-seo description">SISNOTIFICAÇÃO</h2>
                <h4 class="h1-seo description">Selecione a Concessionária:</h4>
              </div>

              <div class="row d-flex justify-content-between div-empresas">
                <div
                  class="
                    col-5
                    card
                    d-flex
                    justify-content-center
                    align-items-center
                    m-2
                    card-empresa
                  "
                  v-for="(csn, idx) in empresas"
                  :key="idx"
                  :id="csn.sigla"
                >
                  <div v-for="(logo, i) in logomarcas" :key="i">
                    <img
                      v-if="logo.sigla === csn.sigla"
                      :alt="logo.sigla"
                      :src="logo.base64"
                      class="card-img-top m-1"
                      style="min-width: 8rem;"
                      @click="selectedEmpresa(csn)"
                    />
                  </div>
                </div>
              </div>

              <b-alert :show="alert.message != null" :variant="alert.type === 'alert-danger' ? 'danger' : 'success'">
                <span class="font-weight-bold">{{ alert.type === 'alert-danger' ? 'Erro' : 'Sucesso' }}</span>
                <p>{{ alert.message }}</p>
              </b-alert>
            </card>
          </div>
        </div>
      </form>
    </div>

    <main-footer class="colorFooter"></main-footer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Card, Button, FormGroupInput } from '@/components';
import MainFooter from '@/layout/MainFooter';
import { empresaService } from '../_services';

export default {
  name: 'selecaoEmpresa',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      backgroundLogin: "background-image: url('img/trevo.jpg')",
      selecaoEmpresa: '',
      empresas: [],
      logomarcas: [],
      loading: false,
      submitted: false
    };
  },
  computed: {
    ...mapState('account', ['status']),
    alert() {
      return this.$store.state.alert;
    }
  },
  mounted() {
    if (window.config && window.config.SIGLA_ASSETS) {
      const siglaAssets = window.config.SIGLA_ASSETS;
      this.backgroundLogin = `background-image: url('img/${siglaAssets}/trevo.jpg')`;
    }

    this.$store.dispatch('alert/clear');

    empresaService.getLogomarcas().then(response => {
      this.logomarcas = [];
      var rawData = response.data;
      for (const logoData of rawData) {
        this.logomarcas.push({
          sigla: logoData['empresa'],
          base64:
            'data:' + (logoData['extensao'] == 'png' ? 'image/png' : 'image/jpeg') + ';base64, ' + logoData['imagem']
        });
      }
    });

    empresaService.find().then(response => {
      this.empresas = response.data;

      // Seleciona a primeira o primeiro obj
      if (this.empresas) {
        this.empresa = this.empresas[0];
      }

      // Se houver apenas uma empresa, ele seleciona automaticamente
      if (this.empresas.length == 1) {
        this.redirecionado = true;
        (this.empresa = this.empresas[0]), localStorage.setItem('empresaAtual', JSON.stringify(this.empresa));
        setTimeout(() => this.$router.push({ path: '/' }), 3000);
      }

      this.loading = false;

      // Ao trocar de empresa, seleciona a empresa atual que o usuario estava logado.
      /*
      let empresaAtual = localStorage.getItem('empresaAtual')
      if (empresaAtual != null || empresaAtual != undefined ) {
        this.empresa = empresaAtual
        console.log('troca.empresa ==> ', this.empresa)
      */
    });
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    selectedEmpresa(emp) {
      /* Seleciona a empresa conforme selecionado */

      this.empresa = emp;

      if (this.empresa == null || this.empresa == undefined) {
        this.$notify({
          group: 'msgs',
          title: 'Erro',
          text: 'Selecione uma empresa.',
          type: 'error'
        });
      } else {
        localStorage.setItem('empresaAtual', JSON.stringify(this.empresa));
        this.$store.dispatch('empresa/select', this.empresa);
        this.$router.push({ name: 'index' });
      }
    }
  },
  watch: {
    $route() {
      // clear alert on location change
      this.$store.dispatch('alert/clear');
    }
  }
};
</script>
<style scoped>
.colorFooter {
  color: white;
}
.card-empresa {
  min-width: 10rem;
  min-height: 10rem;
  background-color: rgba(255, 255, 255, 0.387);
  border: 1px solid rgb(46, 51, 145);
}
.card-empresa:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.809);
  transition-duration: 1s;
}

.div-empresas {
  height: 285px;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  font-size: 13px !important;
  padding: 5px;
}
</style>
