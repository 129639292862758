<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h3 class="h3-seo mt-5">{{ demanda.titulo }}</h3>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <form>
        <b-row class="mx-2">
          <b-col cols="9">
            <b-card class="mt-3">
              <div slot="header">
                <b-row>
                  <b-col cols="6">
                    <span class="h5">Solicitante: {{ demanda.usuario.nome }}</span>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <span class="h5 ">Protocolo: {{ demanda.nroProtocolo }}</span>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col cols="2">
                  <b-form-group label="KM Inicial:">
                    <b-form-input v-model="demanda.kmInicial" :readonly="true" />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-form-group label="KM Final:">
                    <b-form-input v-model="demanda.kmFinal" :readonly="true" />
                  </b-form-group>
                </b-col>
                <b-col cols="8">
                  <b-form-group label="Cidade:">
                    <b-form-input v-model="demanda.cidade.descricao" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="7">
                  <b-form-group label="Rodovia:">
                    <b-input-group>
                      <b-form-input v-model="demanda.trecho.descricao" :readonly="true" />
                      <b-input-group-append>
                        <b-button variant="info" @click="toggleMapDialog">
                          <i class="now-ui-icons location_pin" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="5">
                  <b-form-group :label="labelTipo">
                    <b-form-input v-model="demanda.tipo.descricao" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group :label="labelObjetivo">
                    <b-form-input v-model="demanda.objetivo.descricao" :readonly="true" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Local:">
                    <b-form-input :value="getLocalDemanda()" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="6" v-if="tipoDemanda === 'FX'">
                  <b-form-group label="Área:">
                    <b-form-input v-model="demanda.area.descricao" :readonly="true" />
                  </b-form-group>
                </b-col>
                <b-col cols="6" v-if="tipoDemanda === 'FX'">
                  <b-form-group label="Nomenclatura Alça:">
                    <b-form-input :value="getNomenclaturaAlca()" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group label="Faixa:">
                    <b-form-input :value="getFaixaDemanda()" :readonly="true" />
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group label="Sentido:">
                    <b-form-input :value="demanda.sentido == 'C' ? 'Crescente' : 'Decrescente'" :readonly="true" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Observações:">
                    <b-form-textarea v-model="demanda.observacoes" rows="3" :readonly="true" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="3">
            <b-card class="mt-3" header-bg-variant="primary" footer-bg-variant="secondary">
              <div slot="header" class="text-white">
                {{ demanda.statusInfo.descricao }}
              </div>

              <b-row class="my-1">
                <span class="p-2 text-muted"
                  ><i class="fas fa-location-arrow icon mr-2"></i>
                  <a @click="openDadosAdicionais" style="cursor: pointer;">{{
                    demanda.trecho.descricao + ', do KM ' + demanda.kmInicial + ' ao KM ' + demanda.kmFinal
                  }}</a>
                </span>
              </b-row>
              <b-row class="my-1">
                <span class="p-2 text-muted"
                  ><i class="fas fa-users icon mr-2"></i>Nº Habitantes:
                  {{
                    infoCidade != undefined ? formatter(infoCidade.habitantes).format('000,000') : 'Não disponível'
                  }}</span
                >
              </b-row>
              <b-row class="my-1">
                <span class="p-2 text-muted"
                  ><i class="fas fa-dollar-sign icon mx-1 pr-2"></i>PIB:
                  {{ infoCidade != undefined ? formatter(infoCidade.pib).format('$0,0.00') : 'Não disponível' }}</span
                >
              </b-row>
              <b-row class="my-1">
                <span class="p-2 text-muted"
                  ><i class="fas fa-car icon mr-2"></i>Acidentes (%):
                  {{ infoSegmento != undefined ? infoSegmento.acidentes : 'Não disponível' }}</span
                >
              </b-row>
              <b-row class="my-1">
                <span class="p-2 text-muted"
                  ><i class="fas fa-road icon mr-2"></i>Tráfego:
                  {{ infoSegmento != undefined ? infoSegmento.trafegoVdm : 'Não disponível' }}</span
                >
              </b-row>
              <b-row class="my-1">
                <span class="p-2 text-muted"
                  ><i class="fas fa-user icon mr-2"></i>Nº Pedestres:
                  {{ infoSegmento != undefined ? infoSegmento.pedestres : 'Não disponível' }}</span
                >
              </b-row>

              <b-row class="my-1">
                <span class="p-2 text-muted">
                  <i class="fas fa-equals icon mr-2"></i>
                  Índices de priorização:
                </span>
                <ul class="listaPrioriza text-muted">
                  <li>Município:</li>
									<li>Tráfego:</li>
									<li>Acidentes:</li>
									<li>Global:</li>

                  <li>{{ getIndiceFmt(demanda.indicePriorizaMunic) }}</li>
                  <li>{{ getIndiceFmt(demanda.indicePriorizaTrafego) }}</li>
                  <li>{{ getIndiceFmt(demanda.indicePriorizaAcidente) }}</li>
                  <li>{{ getIndiceFmt(demanda.indicePriorizacaoGlobal) }}</li>
                </ul>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <span class="badge badge-default">
              <i class="fas fa-comments mr-1 mt-1 mb-1" />
              Comentários ({{ historicos.length }})
            </span>
          </b-col>
          <b-col cols="12">
            <div class="mt-1 mb-1 mr-2">
              <p class="lead" v-if="historicos == undefined || historicos.length == 0">
                Não há comentários disponíveis nesta demanda.
              </p>
              <b-list-group>
                <b-list-group-item
                  v-for="hist in historicos"
                  :key="'hst-' + hist.id.sequencia"
                  class="flex-column align-items-start"
                  href="#"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <div>
                      <AccountDetails v-if="hist.origem == 1" class="mr-1" v-b-tooltip.hover title="Concessionária" />
                      <AccountTie v-if="hist.origem == 2" class="mr-1" v-b-tooltip.hover title="Agência reguladora" />
                      <AccountGroup
                        v-if="hist.origem == 3"
                        class="mr-1"
                        v-b-tooltip.hover
                        title="Usuário(s) Sisdemanda"
                      />
                      <span class="small mr-1">{{ hist.usuario.nome }}&nbsp;&minus;</span>
                      <span>{{ hist.descricao }}</span>
                    </div>
                    <span class="small">
                      <font-awesome-icon icon="calendar-alt" />
                      {{ hist.datHorHistorico | formatDate }}
                    </span>
                  </div>

                  <div v-if="hist.historicoResposta">
                    <span class="small ml-2"
                      >{{ hist.historicoResposta.datHorHistorico | formatDate }},
                      {{ hist.historicoResposta.usuario.nome }}:&nbsp;</span
                    >
                    {{ hist.historicoResposta.descricao }}
                  </div>

                  <div class="text-right" v-if="hist.anexo != undefined">
                    <a
                      :href="getMediaUrl(hist.id.idEmpresa, hist.anexo.uuid)"
                      :download="hist.anexo.nome"
                      class="nav-item"
                      target="_blank"
                    >
                      <font-awesome-icon icon="file-download" />
                      <span class="ml-1">Baixar</span>
                    </a>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <n-button type="primary" v-b-modal.publishModal round size="md" class="mb-1">
              <font-awesome-icon icon="comment" />&nbsp;Adicionar comentário
            </n-button>

            <b-modal id="publishModal" title="Incluir comentário" hide-footer>
              <b-row>
                <b-col cols="12">
                  <b-form-textarea
                    class="form-control mb-2"
                    rows="5"
                    cols="180"
                    placeholder="Adicionar comentário..."
                    v-model="comentario"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <b-form-file
                      v-model="file"
                      placeholder="Selecione o arquivo para anexar"
                      drop-placeholder="Arraste o arquivo aqui"
                      :multiple="false"
                    ></b-form-file>

                    <b-form-select class="form-control" v-model="tipoAnexo">
                      <option :value="null">Selecione</option>
                      <optgroup label="Projeto">
                        <option value="PF">Projeto Funcional</option>
                        <option value="PB">Projeto Básico</option>
                        <option value="PE">Projeto Executivo</option>
                      </optgroup>
                      <optgroup label="Orçamento">
                        <option value="OF">Orçamento Funcional</option>
                        <option value="OB">Orçamento Básico</option>
                        <option value="OE">Orçamento Executivo</option>
                      </optgroup>
                      <option value="FC">Ofício</option>
                      <option value="FT">Foto</option>
                      <option value="OU">Outros</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <n-button type="primary" round block size="md" @click="handlePublish">
                    <font-awesome-icon icon="comment" />
                    <span class="ml-1">Publicar</span>
                  </n-button>
                </b-col>
                <b-col cols="6">
                  <n-button type="secondary" round block size="md" @click="handleCancel">
                    <font-awesome-icon icon="window-close" />
                    <span class="ml-1">Cancelar</span>
                  </n-button>
                </b-col>
              </b-row>
            </b-modal>
          </b-col>
        </b-row>

        <b-modal id="mapModal" size="xl" title="Localização da demanda: ">
          <mapas :demanda="demanda" :kmlData="kmlData"></mapas>
        </b-modal>

        <b-modal id="dadosAdicionais" size="xl" title="Informações do Trecho">
          <div>
            <b-table
              table-class="table-responsive text-nowrap"
              striped
              hover
              :show-empty="true"
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fieldsDadosAdicionais"
              :items="dadosAdicionais"
              empty-text="Não há dados disponíveis."
              :busy="show"
            >
              <template v-slot:table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Carregando...</strong>
                </div>
              </template>

              <template v-slot:cell(pista)="row">{{
                row.item.pista != null && row.item.pista == 'S' ? 'Simples' : 'Dupla'
              }}</template>

              <template v-slot:cell(passarelaBoolean)="row">{{
                row.item.passarelaBoolean != null && row.item.passarelaBoolean == true ? 'Sim' : 'Não'
              }}</template>

              <template v-slot:cell(defensaBoolean)="row">{{
                row.item.defensaBoolean != null && row.item.defensaBoolean == true ? 'Sim' : 'Não'
              }}</template>

              <template v-slot:cell(barreiraBoolean)="row">{{
                row.item.barreiraBoolean != null && row.item.barreiraBoolean == true ? 'Sim' : 'Não'
              }}</template>

              <template v-slot:cell(canteiroBoolean)="row">{{
                row.item.canteiroBoolean != null && row.item.canteiroBoolean == true ? 'Sim' : 'Não'
              }}</template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
          </div>
        </b-modal>
      </form>

      <div class="separator separator-primary"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { Button, FormGroupInput } from '@/components';
import Mapas from '@/pages/Mapas.vue';
import AccountDetails from 'vue-material-design-icons/AccountDetails';
import AccountGroup from 'vue-material-design-icons/AccountGroup';
import AccountTie from 'vue-material-design-icons/AccountTie';

import numeral from 'numeral';
import 'numeral/locales';

export default {
  name: 'demanda',
  bodyClass: 'demanda-page',
  components: {
    AccountGroup,
    AccountDetails,
    AccountTie,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Mapas.name]: Mapas
  },
  data() {
    return {
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
      formatter: undefined,
      idDemanda: 0,
      demanda: {
        titulo: null,
        nroProtocolo: null,
        kmInicial: null,
        kmFinal: null,
        objetivo: { descricao: null },
        cidade: { cidade: { descricao: null } },
        rodovia: { rodovia: { descricao: null } },
        trecho: { descricao: null },
        tipo: { descricao: null },
        area: { descricao: null },
        status: null,
        statusInfo: { descricao: null },
        usuario: { nome: null }
      },
      infoCidade: undefined,
      infoSegmento: undefined,
      historicos: [],
      kmlData: [],
      comentario: '',
      file: null,
      observacao: '',
      tipoAnexo: '',
      tipoDemanda: this.$store.state.account.tipo,
      labelTipo: 'Tipo:',
      labelObjetivo: 'Objetivo:',
      dadosAdicionais: [],
      fieldsDadosAdicionais: [
        { key: 'kmInicial', label: 'KM Inicial' },
        { key: 'kmFinal', label: 'KM Final' },
        { key: 'pista', label: 'Pista' },
        { key: 'faixa', label: 'Faixa' },
        { key: 'larguraFaixa', label: 'Largura Faixa' },
        { key: 'larguraAcost', label: 'Largura Acost.' },
        { key: 'barreiraBoolean', label: 'Barreira' },
        { key: 'defensaBoolean', label: 'Defensa' },
        { key: 'canteiroBoolean', label: 'Canteiro' },
        { key: 'passarelaBoolean', label: 'Passarela' },
        { key: 'relevo', label: 'Relevo' },
        { key: 'nroAcesso', label: 'N Acesso' },
        { key: 'classe', label: 'Classe' },
        { key: 'vdm', label: 'VDM' },
        { key: 'vhp', label: 'VHP' },
        { key: 'nvlSvc', label: 'Nvl. Svc.' },
        { key: 'acid', label: 'Acid.(%)' },
        { key: 'contagemPedestres', label: 'Contagem Pedestres' }
      ],
      perPage: 5,
      currentPage: 1,
      show: false
    };
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual']),
    rows() {
      return this.dadosAdicionais.length;
    }
  },
  mounted() {
    if (window.config) {
      if (window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundTopo = `background-image: url('/img/${siglaAssets}/solicitacao.jpg')`;
      }

      if (window.config.LABELS) {
        this.labelTipo = window.config.LABELS.demanda.tipo;
        this.labelObjetivo = window.config.LABELS.demanda.objetivo;
      }
    }

    numeral.locale('pt-br');
    this.formatter = numeral;

    this.loadData();
  },
  methods: {
    getMediaUrl(idEmpresa, uuid) {
      const serverUrl =
        process.env.NODE_ENV === 'production' ? window.config.VUE_APP_API_LOCATION : process.env.VUE_APP_API_LOCATION;
      return `${serverUrl}/viewFile?idEmpresa=${idEmpresa}&uuid=${uuid}`;
    },
    getFaixaDemanda() {
      if (this.demanda == null || this.demanda === undefined) {
        return '';
      }

      if (this.demanda.faixa === 'FAIXA_1') {
        return '1';
      } else if (this.demanda.faixa === 'FAIXA_2') {
        return '2';
      } else if (this.demanda.faixa === 'FAIXA_3') {
        return '3';
      } else if (this.demanda.faixa === 'FAIXA_4') {
        return '4';
      } else if (this.demanda.faixa === 'FAIXA_5') {
        return '5';
      } else if (this.demanda.faixa === 'FAIXA_6') {
        return '6';
      } else if (this.demanda.faixa === 'ACOSTAMENTO_INTERNO') {
        return 'Acostamento Interno';
      } else if (this.demanda.faixa === 'ACOSTAMENTO_EXTERNO') {
        return 'Acostamento Externo';
      }

      return '';
    },
    getIndiceFmt(valor) {
      return numeral(valor).format('#,###.#');
    },
    getLocalDemanda() {
      if (this.demanda == null || this.demanda === undefined) {
        return '';
      }

      if (this.demanda.local === 'EIXO_TRONCAL') {
        return 'Eixo Troncal';
      } else if (this.demanda.local === 'MARGINAL_DIREITA') {
        return 'Marginal Direita';
      } else if (this.demanda.local === 'MARGINAL_ESQUERDA') {
        return 'Marginal Esquerda';
      } else if (this.demanda.local === 'DISPOSITIVO') {
        return 'Dispositivo';
      }

      return '';
    },
    getNomenclaturaAlca() {
      if (this.demanda == null || this.demanda === undefined) {
        return '';
      }

      const alcas = [];
      // Fill Nomenclaturas : Ramos
      for (let i = 100; i <= 900; i += 100) {
        alcas.push({ value: 'RAMO_' + i, text: 'Ramo ' + i });
      }

      // Fill Nomenclaturas : Rotatórias
      for (let i = 100; i <= 900; i += 100) {
        alcas.push({ value: 'ROTATORIA_', text: 'Rotatória ' + i });
      }

      for (let i = 0; i < alcas.length; i++) {
        if (alcas[i].value == this.demanda.nomenclaturaAlca) {
          return alcas[i].text;
        }
      }

      return '';
    },
    loadData() {
      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const idDemanda = this.$route.params.idDemanda;

      Vue.axios
        .get(`/api/demandas/${sigla}/${idDemanda}`, options)
        .then(response => {
          this.demanda = response.data.demanda;
          if (response.data.historicos != undefined) {
            this.historicos = response.data.historicos;
          }

          if (response.data.cidade != undefined) {
            this.infoCidade = response.data.cidade;
          }

          if (response.data.segmento != undefined) {
            this.infoSegmento = response.data.segmento;
          }
        })
        .catch(error => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error,
            type: 'error'
          });
        });
    },
    handleCancel() {
      this.$bvModal.hide('publishModal');
    },
    handlePublish() {
      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const token = this.$store.state.account.user;
      const idDemanda = this.$route.params.idDemanda;
      const requestOpts = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      const formData = new FormData();
      formData.append('comentario', this.comentario);
      if (this.file !== undefined && this.file != null) {
        if (this.tipoAnexo === undefined || this.tipoAnexo == null || this.tipoAnexo == '') {
          this.$notify({
            group: 'msgs',
            title: 'Upload',
            text: 'Tipo de Anexo não informado.',
            type: 'error'
          });
          return;
        }
        formData.append('file', this.file);
        formData.append('tipoAnexo', this.tipoAnexo);
      }

      Vue.axios
        .post(`/api/demandas/${sigla}/${idDemanda}/publicar`, formData, requestOpts)
        .then(response => {
          this.comentario = '';
          this.historicos = response.data;
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: 'Comentário enviado com sucesso.',
            type: 'info'
          });

          this.$bvModal.hide('publishModal');
        })
        .catch(error => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error.response && error.response.data.message ? error.response.data.message : error,
            type: 'error'
          });
        });
    },
    toggleMapDialog() {
      this.$bvModal.show('mapModal');
    },
    openDadosAdicionais() {
      this.show = true;

      let rodovia = this.demanda.rodovia.descricao;
      var rodoviaTxt = rodovia.replace(/\s/g, '');
      var idTrecho = this.demanda.idTrecho;
      var faixa = this.demanda.faixa;
      var kmInicial = this.demanda.kmInicial;
      var kmFinal = this.demanda.kmFinal;
      let url = `/api/rodovias/${rodoviaTxt}/trechos/${idTrecho}/segmentos?faixa=${faixa}&kmInicial=${kmInicial}&kmFinal=${kmFinal}`;

      const token = this.$store.state.account.user;
      const options = { headers: { Authorization: `Bearer ${token}` } };

      Vue.axios
        .get(url, options)
        .then(response => {
          this.$bvModal.show('dadosAdicionais');
          this.dadosAdicionais = response.data;
          this.show = false;
        })
        .catch(error => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error,
            type: 'error'
          });
        });
    }
  }
};
</script>

<style scoped>
.listaPrioriza {
  list-style-type: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
