<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img :src="logomarca" class="logomarca" :v-if="mostrarLogo === true" />
      </router-link>
    </template>
    <template slot="navbar-menu">
      <drop-down tag="li" icon="fas fa-user" class="nav-item" v-if="this.status.loggedIn">
        <!-- <nav-link to="/perfil"> <i class="now-ui-icons users_circle-08"></i> Perfil </nav-link> -->
        <a class="dropdown-item" title="Logout" href="#" @click="logout">
          <font-awesome-icon icon="sign-out-alt" />
          <p>Logout</p>
        </a>
      </drop-down>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Siga-nos no Twitter"
          data-placement="bottom"
          href="https://twitter.com"
          target="_blank"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Curta-nos no Facebook"
          data-placement="bottom"
          href="https://www.facebook.com"
          target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Siga-nos no Instagram"
          data-placement="bottom"
          href="https://www.instagram.com"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li>
      <div class="btn-group" v-if="empresas != null && empresaAtual != null && empresas.length > 1">
        <b-dropdown id="dropdown-1" class="m-md-2 selectEmp" :text="empresaAtual.nomeFantasia">
          <b-dropdown-item
            v-for="emp in empresas"
            :key="emp.sigla"
            :active="emp.sigla === empresaAtual"
            @click="handleSelectEmpresa(emp)"
          >
            {{ emp.nomeFantasia }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar } from '@/components';
import { Popover } from 'element-ui';
import { mapState } from 'vuex';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
    mostrarLogo: Boolean
  },
  components: {
    DropDown,
    Navbar,
    // NavLink,
    [Popover.name]: Popover
  },
  computed: {
    ...mapState('account', ['status']),
    ...mapState('empresa', ['empresas', 'empresaAtual']),
    ...mapState('logomarca', ['logomarcas', 'logomarcaAtual'])
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      logomarca: undefined
    };
  },
  methods: {
    handleSelectEmpresa(empresa) {
      if (empresa.sigla != this.empresaAtual.sigla) {
        this.$store.dispatch('empresa/select', empresa).then(() => {
          this.getLogomarca();
          this.$router.go();
          this.$router.push('/');
        });
      }
    },
    logout() {
      this.logomarca = undefined;
      // this.mostraLogo = false;
      this.$store.dispatch('account/logout').then(() => {
        this.$router.push('login');
      });
    },
    getLogomarca() {
      this.logomarca = undefined;
      if (this.empresaAtual != undefined) {
        if (this.logomarcas != null) {
          for (var item of this.logomarcas) {
            if (item.empresa === this.empresaAtual.sigla) {
              this.logomarca = 'data:image/png;base64,' + item.imagem;
              break;
            }
          }
        }
      }

      if (this.logomarca == undefined) {
        if (window.config) {
          if (window.config.SIGLA_ASSETS) {
            const siglaAssets = window.config.SIGLA_ASSETS;
            this.logomarca = `/img/${siglaAssets}/logo.png`;
          }
        }

        if (this.logomarca == undefined) this.logomarca = process.env.VUE_APP_CONTEXT + 'img/oti-slim.png';
      }
    }
  },
  mounted() {
    this.getLogomarca();
  }
};
</script>
<style>
.logomarca {
  width: 150px;
  height: auto;
}

.selectEmp .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}
</style>
