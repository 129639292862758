<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h3 class="h3-seo mt-5">Notificação - {{ notificacao.id.idNotificacao }}</h3>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="section section-contact-us text-center">
        <div class="container">
          <form>
            <div class="col-md-12 ml-auto mr-auto text-left mt-4">
              <b-card>
                <template #header>
                  <h5 class="mb-0">Dados Gerais</h5>
                </template>
                <b-row>
                  <b-col md="6">
                    <b-form-group id="tipoDocumento" label="Tipo notificação:" label-for="tipoDocumento">
                      <b-form-textarea id="tipoDocumento" v-model="notificacao.tipoNotificacao.descricao"
                        readonly></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-row>
                      <b-col md="12">
                        <b-form-group id="entidade" label="Entidade:" label-for="entidade">
                          <b-form-input id="entidade" v-model="notificacao.entidade.nome" type="text"
                            readonly></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group id="setor" label="Setor:" label-for="setor">
                          <b-form-input id="setor" v-model="notificacao.setor.sigla" type="text"
                            readonly></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-form-group id="setor" style="margin-top: 28px" label-for="setor">
                          <b-form-input id="setor" v-model="notificacao.setor.descricao" type="text"
                            readonly></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-form-group id="responsavelEntidade" label="Responsável da Entidade:"
                      label-for="responsavelEntidade">
                      <b-form-input id="responsavelEntidade" v-model="notificacao.responsavelSetor.nome" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label=" Nº Documento:" label-for="nrodocumento">
                      <b-form-input id="nrodocumento" v-model="notificacao.nroDocumento" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label=" Nº Processo:" label-for="nroProcesso">
                      <b-form-input id="nroProcesso" v-model="notificacao.nroProcesso" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="dataRecebimento" label="Data Recebimento:" label-for="dataRecebimento">
                      <b-form-input id="dataRecebimento" v-model="notificacao.datRecebimento" type="date"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="horaRecebimento" label="Hora Registro:" label-for="horaRecebimento">
                      <b-form-input id="horaRecebimento" v-model="notificacao.horRegistro" type="time"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="dataVencimento" label="Data Vencimento:" label-for="dataVencimento">
                      <b-form-input id="dataVencimento" v-model="notificacao.datVencimento" type="date"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="horaVencimento" label="Hora Vencimento:" label-for="horaVencimento">
                      <b-form-input id="horaVencimento" v-model="notificacao.horVencimento" type="time"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="9">
                    <b-form-group id="assunto" label="Assunto:" label-for="assunto">
                      <b-form-textarea id="assunto" v-model="notificacao.descricao" readonly></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>

              <b-card>
                <template #header>
                  <h5 class="mb-0">Rodovias</h5>
                </template>

                <b-row>
                  <b-col md="12">
                    <b-table striped hover :fields="fieldsRodovia" :items="rodovias" :show-empty="true"
                      empty-text="Não há rodovias disponíveis.">
                    </b-table>
                  </b-col>
                </b-row>
              </b-card>

              <b-card>
                <template #header>
                  <h5 class="mb-0">Notificação</h5>
                </template>
                <b-row>
                  <b-col md="6">
                    <b-form-group id="grupo" label="Grupo:" label-for="grupo">
                      <b-form-input id="grupo" v-model="notificacao.grupo.grupo" type="text" readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group id="nivel" label="Nível:" label-for="nivel">
                      <b-form-input id="nivel" v-model="notificacao.nivel.nivel" type="text" readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group id="classe" label="Classe:" label-for="classe">
                      <b-form-input id="classe" v-model="notificacao.classe.descricao" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group id="subClasse" label="Subclasse:" label-for="subClasse">
                      <b-form-input id="subClasse" v-model="notificacao.subClasse.descricao" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group id="statusProcesso" label="Status Processo:" label-for="statusProcesso">
                      <b-form-input id="statusProcesso" v-model="notificacao.statusProcesso.descricao" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="dias" label="Dias:" label-for="dias">
                      <b-form-input id="dias" v-model="notificacao.diasNotificacao" type="text" readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="quantidade" label="Quantidade:" label-for="quantidade">
                      <b-form-input id="quantidade" v-model="notificacao.multiplicador" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="agravante" label="Agravante (%):" label-for="agravante">
                      <b-form-input id="agravante" v-model="notificacao.valorAgravante" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="atenuante" label="Atenuante (%):" label-for="atenuante">
                      <b-form-input id="atenuante" v-model="notificacao.valorAtenuante" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="valorNotificacao" label="Valor Notificação:" label-for="valorNotificacao">
                      <b-form-input id="valorNotificacao" v-model="valorNotificacao" type="text"
                        readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group id="valorNotificacao" label="Valor Atual:" label-for="valorNotificacao">
                      <b-form-input id="valorNotificacao" v-model="valorAtual" type="text" readonly></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>

              <b-card v-if="anexos.length > 0">
                <template #header>
                  <h5 class="mb-0">Anexos</h5>
                </template>
                <b-row>
                  <b-col md="12" v-for="anexo in anexos" :key="anexo.seqAnexo">
                    <b-form-group id="anexoExtra" label-for="anexoExtra">
                      <b-list-group>
                        <b-list-group-item class="flex-column align-items-start">
                          <div class="d-flex w-100 justify-content-between">
                            <span class="small mr-1">{{ anexo.anexo.nome }}</span>
                            <div class="text-right">
                              <a @click="getMediaUrl(anexo.anexo.uuid, anexo.anexo.nome, anexo.anexo.tipo)"
                                :download="anexo.anexo.nome" class="nav-item" target="_blank">
                                <b-icon icon="download"></b-icon>
                                <span style="cursor: pointer" class="ml-1">Baixar</span>
                              </a>
                            </div>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Button, FormGroupInput } from '@/components';

import { notificacaoService } from '@/_services';

import numeral from 'numeral';
import 'numeral/locales';

export default {
  name: 'demanda',
  bodyClass: 'demanda-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
      notificacao: {
        id: {},
        datVencimento: '',
        horVencimento: '',
        tipoNotificacao: {},
        entidade: {},
        setor: {},
        responsavelSetor: {},
        statusNotificacao: {},
        grupo: {},
        nivel: {},
        classe: {},
        subClasse: {},
        statusProcesso: {},
        rodovias: [],
        anexos: [],
      },

      //   Tabela de Rodovias
      rodovias: [],
      fieldsRodovia: [
        {
          key: 'rodovia.descricaoCompleta',
          label: 'Descrição',
          thStyle: { width: '55% !important' },
        },
        {
          key: 'kmInicial',
          label: 'Km Inicial',
          thStyle: { width: '10% !important' },
        },
        {
          key: 'kmFinal',
          label: 'Km Final',
          thStyle: { width: '10% !important' },
        },
        {
          key: 'sentido',
          label: 'Sentido',
          thStyle: { width: '25% !important' },
        },
      ],

      // Tabela de Anexos
      anexos: [],
      fieldsNotificacaoAnexos: [
        {
          key: 'descricao',
          label: 'Descrição',
          thStyle: { width: '10% !important' },
        },
        {
          key: 'nomeArquivo',
          label: 'Nome do Arquivo',
          thStyle: { width: '10% !important' },
        },
        {
          key: 'tamanhoArquivo',
          label: 'Tamanho',
          thStyle: { width: '10% !important' },
        },
        {
          key: 'tipoAnexo',
          label: 'Tipo Anexo',
          thStyle: { width: '10% !important' },
        },
      ],
      perPage: 10,
      currentPage: 1,

      valorNotificacao: null,
      valorAtual: null
    };
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual']),
    rows() {
      return this.notificacoes.length;
    },
  },
  methods: {
    findNotificacaoById(siglaEmpresa, idNotificacao) {
      notificacaoService
        .getNotificacaoById(siglaEmpresa, idNotificacao)
        .then((response) => {
          this.notificacao = response.data;

          // Separa a hora e a data de vencimento pra mostrar em campos separados na tela
          // this.notificacao.datVencimento = this.notificacao.datVencimento;
          // this.notificacao.horVencimento = this.notificacao.horVencimento;

          // Verificação dos objetos da tela
          if (this.notificacao.tipoNotificacao === undefined) {
            this.notificacao.tipoNotificacao = {};
          }

          if (this.notificacao.entidade === undefined) {
            this.notificacao.entidade = {};
          }

          if (this.notificacao.setor === undefined) {
            this.notificacao.setor = {};
          }

          if (this.notificacao.responsavelSetor === undefined) {
            this.notificacao.responsavelSetor = {};
          }

          if (this.notificacao.statusNotificacao === undefined) {
            this.notificacao.statusNotificacao = {};
          }

          if (this.notificacao.grupo === undefined) {
            this.notificacao.grupo = {};
          }

          if (this.notificacao.nivel === undefined) {
            this.notificacao.nivel = {};
          }

          if (this.notificacao.classe === undefined) {
            this.notificacao.classe = {};
          }

          if (this.notificacao.subClasse === undefined) {
            this.notificacao.subClasse = {};
          }

          if (this.notificacao.statusProcesso === undefined) {
            this.notificacao.statusProcesso = {};
          }

          // Adiciona as rodovias ao card de Rodovias
          this.rodovias = [];
          if (this.notificacao.rodovias != undefined) {
            this.rodovias = this.notificacao.rodovias;
          }

          // Adiciona as anexos ao card de Anexos
          this.anexos = [];
          if (this.notificacao.anexos != undefined) {
            this.anexos = this.notificacao.anexos;
          }

          this.valorNotificacao = numeral(this.notificacao.valor).format('$ #,##0.00');
          this.valorAtual = numeral(this.notificacao.valorAtual).format('$ #,##0.00');
        })
        .catch((error) => {
          this.$notify({
            group: 'msgs',
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error',
          });
        });
    },
    getAnexosOFC() {
      if (this.notificacao == undefined || this.notificacao.anexos == undefined) return null;

      let anexosOFC = [];
      for (const anx of this.notificacao.anexos) {
        if (anx.tipoAnexo === 'OFC') anexosOFC.push(anx);
      }

      return anexosOFC;
    },
    getAnexosRSP() {
      if (this.notificacao == undefined || this.notificacao.anexos == undefined) return null;

      let anexosRSP = [];
      for (const anx of this.notificacao.anexos) {
        if (anx.tipoAnexo === 'RSP') anexosRSP.push(anx);
      }

      return anexosRSP;
    },
    getAnexosAdicionais() {
      if (this.notificacao == undefined || this.notificacao.anexos == undefined) return null;

      let anexosExt = [];
      for (const anx of this.notificacao.anexos) {
        if (anx.tipoAnexo === 'ANX') anexosExt.push(anx);
      }

      return anexosExt;
    },
    getMediaUrl(uuid, nomeArquivo, mimeType) {
      console.log(uuid)
      const siglaEmpresa = this.$route.params.siglaEmpresa;

      notificacaoService.downloadAnexo(siglaEmpresa, 'CRP', uuid).then((response) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(new Blob([response.data], { type: mimeType }));
        link.download = nomeArquivo;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 5000);
      });
    },

    getFormatNumeral(value) {
      let formatNumeral = numeral(value, '###,##');
      return formatNumeral;
    },
  },
  mounted() {
    if (window.config) {
      if (window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundTopo = `background-image: url('/img/${siglaAssets}/solicitacao.jpg')`;
      }
    }

    numeral.locale('pt-br');

    const sigla = this.$route.params.siglaEmpresa;
    const idNotif = this.$route.params.idNotificacao;

    this.findNotificacaoById(sigla, idNotif);
  }
};
</script>

<style></style>
