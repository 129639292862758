<template>
  <div class="page-header clear-filter" filter-color="green">
    <div class="page-header-image" :style="backgroundLogin"></div>
    <div class="content">
      <form @submit.prevent="handleSubmit">
        <div class="container">
          <div class="col-md-5 ml-auto mr-auto">
            <card type="login" plain>
              <div slot="header" class="content-top brand">
                <h2 class="h1-seo description">SISNOTIFICAÇÃO</h2>
              </div>

              <fg-input
                class="no-border"
                style=""
                addon-left-icon="now-ui-icons users_circle-08"
                placeholder="Login "
                v-model="userName"
              ></fg-input>

              <fg-input
                class="no-border input-lg"
                inputType="password"
                addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                placeholder="Senha"
                v-model="password"
              ></fg-input>

              <div v-if="refCodeEmpresa === true">
                <b-form-group label="Concessionária:">
                  <b-form-select id="empresa" v-model="empresa">
                    <template #first>
                      <b-form-select-option :value="undefined">
                        Selecione...
                      </b-form-select-option>
                    </template>
                    <b-form-select-option v-for="(emp, idx) in optionsEmpresas" :key="idx" :value="emp.sigla">
                      {{ emp.razaoSocial }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>

              <b-alert :show="alert.message != null" :variant="alert.type === 'alert-danger' ? 'danger' : 'success'">
                <span class="font-weight-bold">{{ alert.type === 'alert-danger' ? 'Erro' : 'Sucesso' }}</span>
                <p>{{ alert.message }}</p>
              </b-alert>

              <template slot="raw-content">
                <div class="card-footer text-center">
                  <b-btn class="btn-primary btn-round btn-block" type="submit">
                    <font-awesome-icon icon="lock" /> <span class="ml-2">Acessar</span>
                  </b-btn>
                </div>
                <!-- <div class="pull-left">
                  <h6>
                    <router-link to="/register" class="link footer-link">Criar conta</router-link>
                  </h6>
                </div>
                <div class="pull-right">
                  <h6>
                    <router-link to="/recuperar" class="link footer-link">Recuperar senha</router-link>
                  </h6>
                </div> -->
              </template>
            </card>
          </div>
        </div>
      </form>
    </div>

    <main-footer class="colorFooter"></main-footer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Card, Button, FormGroupInput } from '@/components';
import MainFooter from '@/layout/MainFooter';
import { coreService } from '@/_services';
import { empresaService } from '@/_services';

export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      backgroundLogin: "background-image: url('img/trevo.jpg')",
      userName: '',
      password: '',
      submitted: false,

      refCodeEmpresa: false,
      optionsEmpresas: [],
      empresa: undefined
    };
  },
  computed: {
    ...mapState('account', ['status']),
    alert() {
      return this.$store.state.alert;
    }
  },
  created() {
    // reset login status
    this.logout();
  },
  mounted() {
    if (window.config && window.config.SIGLA_ASSETS) {
      const siglaAssets = window.config.SIGLA_ASSETS;
      this.backgroundLogin = `background-image: url('img/${siglaAssets}/trevo.jpg')`;
    }

    this.findRefCodeEmpresa();
    this.findEmpresas();

    this.$store.dispatch('alert/clear');
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    handleSubmit() {
      this.submitted = true;

      const { userName, password, empresa } = this;
      if (userName && password) {
        this.login({ userName, password, empresa });
      }
    },
    findRefCodeEmpresa() {
      coreService
        .getAuteticacaoEmpresa()
        .then(response => {
          const refCode = response.data;

          this.refCodeEmpresa = refCode;
        })
        .catch(error => {
          this.busy = false;
          this.$notify({
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error'
          });
        });
    },
    findEmpresas() {
      empresaService
        .findAll()
        .then(response => {
          this.optionsEmpresas = response.data;
        })
        .catch(error => {
          this.optionsEmpresas = [];
          this.busy = false;
          this.$notify({
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error'
          });
        });
    }
  },
  watch: {
    $route() {
      // clear alert on location change
      this.$store.dispatch('alert/clear');
    }
  }
};
</script>
<style>
.colorFooter {
  color: white;
}
</style>
