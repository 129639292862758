import { Parallax } from '../components';
import VueBootstrapTypeahed from 'vue-bootstrap-typeahead';

const globalComponents = {
  install(Vue) {
    Vue.component(Parallax.name, Parallax);
    Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahed);
  }
};

export default globalComponents;
