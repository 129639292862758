import Vue from 'vue';

import { authHeader } from '../_helpers';

export const coreService = {
  getAuteticacaoEmpresa
};

function getAuteticacaoEmpresa() {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/core/refCodes/empresaAutenticacao`, requestCfg);
}
