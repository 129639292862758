<template>
  <div>
    <div class="page-header clear-filter" filter-color="green">
      <parallax class="page-header-image" :style="backgroundLogin"></parallax>
      <div class="container">
        <div class="content-center brand">
          <h1 class="h1-seo">SISNOTIFICAÇÃO</h1>
          <h3>Portal para acompanhamento de notificações</h3>
          <b-row>
            <b-col>
              <router-link :to="{ name: 'pesquisaNotificacao', params: { tipo: 'P' } }" class="btn btn-primary btn-round h6"
                >Pesquisar Notificações</router-link
              >
            </b-col>
          </b-row>
        </div>
        <h6 class="category category-absolute direita">
          Solução
          <a href="https://www.oti.inf.br" target="_blank"> <img src="img/oti-slim.png" class="otisw-logo" /> </a>
        </h6>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { Parallax } from '@/components';
import { mapState } from 'vuex';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual'])
  },
  data() {
    return {
      backgroundLogin: "background-image: url('img/trevo.jpg')",
      statusSel: null,
      status: [],
      demandas: []
    }
  },
  methods: {
    handleNone() {
      // métodos removidos após reavaliação ambientes
    }
  },
  mounted() {
    if (window.config && window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundLogin = `background-image: url('img/${siglaAssets}/trevo.jpg')`;
    }
  }
};
</script>
<style lang="scss">
.index-page .link-solicitacoes {
  top: 70vh;
}

.otisw-logo {
  max-width: 48px;
}
</style>
