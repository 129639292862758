<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h1 class="h1-seo">SISNOTIFICAÇÃO</h1>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <form>
        <div class="col-md-12 mt-3">
          <b-card class="text-left">
            <b-row>
              <b-col md="6">
                <b-form-group id="pesquisar" label="Pesquisar:" label-for="pesquisar">
                  <b-form-input id="pesquisar" v-model="search" ref="search" type="text"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="tipo" label="Tipo:" label-for="tipo">
                  <b-form-select v-model="tipoNotificacao" class="form-control">
                    <option :value="null">Selecione...</option>
                    <option v-for="(tipo, idx) in tiposNotificacoes" :key="idx" :value="tipo.id.idTipoDocumento">
                      {{ tipo.descricao }}
                    </option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="intervalo" label="Intervalo:" label-for="intervalo">
                  <b-form-input type="date" v-model="dataInicial"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="intervalo" style="margin-top: 28px;" label-for="intervalo">
                  <b-form-input type="date" v-model="dataFinal"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="rodovias" label="Rodovias:" label-for="Rodovia">
                  <multiselect
                    v-model="rodoviasId"
                    :options="rodovias"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :hide-selected="true"
                    :preserve-search="true"
                    placeholder="Selecione para filtrar rodovias..."
                    label="descricao"
                    track-by="descricao"
                    :preselect-first="true"
                    select-label="Selecione uma ou mais"
                    deselectLabel="Pressione para remover."
                    selectedLabel="Selecionado"
                  >
                    <template slot="noOptions">
                      <span>Nenhuma rodovia foi carregada.</span>
                    </template>
                    <template slot="selection" slot-scope="{ isOpen }">
                      <span class="multiselect__single" v-if="rodoviasId.length &amp;&amp; !isOpen"
                        >{{ rodoviasId.length }} rodovias selecionadas</span
                      >
                    </template>
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="mt-4">
                  <b-button
                    variant="primary"
                    class="btn-round"
                    title="Executa a consulta conforme a seleção do filtro"
                    @click="handlePesquisar"
                    ><b-icon icon="search" class="mr-2"></b-icon>Pesquisar</b-button
                  >
                  <b-button
                    variant="primary"
                    class="btn-round ml-1"
                    @click="handleLimpar"
                    title="Executa a limpeza do filtro de pesquisa"
                    >Limpar</b-button
                  >
                  <b-button
                    variant="primary"
                    class="btn-round ml-1"
                    @click="handleExportar"
                    title="Exporta as notificações em XLS"
                    >Exportar</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </b-card>

          <b-card>
            <b-row>
              <b-col class="mt-4">
                <b-table
                  table-class="table-responsive text-nowrap"
                  striped
                  hover
                  :fields="fieldsNotificacao"
                  :items="notificacoes"
                  :show-empty="true"
                  empty-text="Não há notificações disponíveis."
                  :per-page="perPage"
                  :current-page="currentPage"
                  :busy="show"
                >
                  <template v-slot:table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Carregando...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(idNotificacao)="row">
                    <a
                      :href="'/notificacao/' + row.item.empresa.sigla + '/' + row.item.id.idNotificacao"
                      target="_blank"
                      >{{ row.item.id.idNotificacao }}</a
                    >
                  </template>

                  <template v-slot:cell(descricao)="row"
                    >{{ row.item.descricao | stringLimit(150)
                    }}{{ row.item.descricao.length >= 150 ? '...' : '' }}</template
                  >

                  <template v-slot:cell(dataRecebimento)="row">{{ row.item.datRecebimento | formatDate }}</template>

                  <template v-slot:cell(valorNotificacao)="row">{{ row.item.valorNotificacao | numeral }}</template>
                </b-table>
                <b-row v-if="notificacoes.length > 0">
                  <b-col align-self="end">
                    <div class="text-right"><b>Quantidade:</b> {{ notificacoes.length }}</div>
                  </b-col>
                </b-row>
                <b-pagination
                  v-if="notificacoes.length >= 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  align="center"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Button, FormGroupInput, Switch } from '@/components';
import { mapState } from 'vuex';

import { notificacaoService, rodoviaService, tipoDocumentoService } from '@/_services';

import numeral from 'numeral';
import 'numeral/locales';

export default {
  name: 'novaNotifivacao',
  bodyClass: 'novaDemanda-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Switch.name]: Switch
  },
  computed: {
    ...mapState('empresa', ['empresas', 'empresaAtual']),
    rows() {
      return this.notificacoes.length;
    }
  },
  data() {
    return {
      backgroundTopo: "background-image: url('img/solicitacao.jpg')",
      empresa: null,

      // Filtro de Notificações
      search: null,
      tipoNotificacao: null,
      tiposNotificacoes: [],
      dataInicial: null,
      dataFinal: null,
      tipoData: null,
      rodoviasId: [],
      rodovias: [],

      //   Tabela de Notificações
      notificacoes: [],
      fieldsNotificacao: [
        { key: 'idNotificacao', label: 'Nº' },
        {
          key: 'descricao',
          label: 'Descrição',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'dataRecebimento',
          label: 'Data',
          thStyle: { width: '25% !important' }
        },
        { key: 'statusNotificacao.descricao', label: 'Status', thStyle: { width: '10% !important' } },
        {
          key: 'grupo.grupo',
          label: 'Grupo',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'nivel.nivel',
          label: 'Nível',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'classe.descricao',
          label: 'Classe',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'subClasse.descricao',
          label: 'Subclasse',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'statusProcesso.descricao',
          label: 'Status Processo',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'diasNotificacao',
          label: 'Dias',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'multiplicador',
          label: 'Quantidade',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'valorAgravante',
          label: 'Agravante (%)',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'valorAtenuante',
          label: 'Antenuante (%)',
          thStyle: { width: '10% !important' }
        },
        {
          key: 'valor',
          label: 'Valor Notificação',
          thStyle: { width: '10% !important' }
        }
      ],
      perPage: 10,
      currentPage: 1,
      show: false
    };
  },
  mounted() {
    numeral.locale('pt-br');

    if (window.config) {
      if (window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundTopo = `background-image: url('/img/${siglaAssets}/solicitacao.jpg')`;
      }
    }
    this.empresa = this.$store.state.empresa.empresaAtual;
    this.$refs.search.focus();

    this.findTipoDocumentos();
    this.findRodovias();
  },
  methods: {
    handleExportar() {
      notificacaoService.exportXls(this.empresa.sigla).then(response => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        link.href = URL.createObjectURL(new Blob([response.data], { type: mimeType }));
        link.download = 'notificacoes.xlsx';
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 5000);
      });
    },
    handlePesquisar() {
      this.findNotificacoes();
    },
    handleLimpar() {
      this.search = null;
      this.tipoNotificacao = null;
      this.dataInicial = null;
      this.dataFinal = null;
      this.rodoviasId = [];
    },
    visualizarNotificacao(idNotificacao) {
      this.$router.push({
        name: 'notificacao',
        params: {
          siglaEmpresa: this.empresa.sigla,
          idNotificacao: idNotificacao
        }
      });
    },
    findNotificacoes() {
      this.show = true;

      const rodoviasParm = [];
      for (const rdv of this.rodoviasId) rodoviasParm.push(rdv.idRodovia);

      notificacaoService
        .findNotificacao(
          this.empresa.sigla,
          this.search,
          this.tipoNotificacao,
          this.dataInicial,
          this.dataFinal,
          rodoviasParm
        )
        .then(response => {
          this.notificacoes = response.data;
          this.show = false;
        })
        .catch(error => {
          this.notificacoes = [];
          this.show = false;
          this.$notify({
            group: 'msgs',
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error'
          });
        });

      this.show = false;
    },
    findRodovias() {
      rodoviaService
        .find(this.empresa.sigla)
        .then(response => {
          for (const rdv of response.data) {
            this.rodovias.push({
              idRodovia: rdv.id,
              descricao: rdv.descricao
            });
          }
        })
        .catch(error => {
          this.rodovias = [];
          this.$notify({
            group: 'msgs',
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error'
          });
        });
    },
    findTipoDocumentos() {
      tipoDocumentoService
        .findTipoDocumento(this.empresa.sigla)
        .then(response => {
          this.tiposNotificacoes = response.data;
        })
        .catch(error => {
          this.tiposNotificacoes = [];
          this.$notify({
            group: 'msgs',
            title: 'Erro',
            text: error.response ? error.response.data.message : error,
            type: 'error'
          });
        });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.overflow-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.multiselect__tags {
  border-radius: 30px !important;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: inherit !important;
}

.multiselect__placeholder,
.multiselect__input {
  margin-left: 15px !important;
  color: #2c2c2c !important;
  line-height: normal !important;
  font-size: 0.8571em !important;
}

.multiselect__option--highlight {
  background: #4795f5;
}
</style>
