<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div
      class="page-header-image"
      style="background-image: url('img/trevo.jpg')"
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <card type="login" plain>
            <div slot="header" class="content-top brand">
              <h1 class="h1-seo">SISNOTIFICAÇÃO</h1>
            </div>

            <fg-input
              class="no-border input-lg"
              addon-left-icon="now-ui-icons text_caps-small"
              placeholder="Senha"
            >
            </fg-input>

            <fg-input
              class="no-border input-lg"
              addon-left-icon="now-ui-icons text_caps-small"
              placeholder="Repetir senha"
            >
            </fg-input>            

              <template slot="raw-content">
                <div class="card-footer text-center">
                  <b-btn class="btn-primary btn-round btn-lg btn-block" type="submit">Alterar</b-btn>
                </div>
              </template>
          </card>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>
<script>
import { Card, Button, FormGroupInput } from '@/components';
import MainFooter from '@/layout/MainFooter';
export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      password: '',
      submitted: false
    }
  },
  methods: {
    handleCriar() {
      // Método para processamento criação senha
    }
  }
};
</script>
<style></style>
