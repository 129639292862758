import Vue from 'vue';

import { authHeader } from '../_helpers';

export const tipoDocumentoService = {
  findTipoDocumento
};

function findTipoDocumento(siglaEmpresa) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };
  return Vue.axios.get(`/tiposDocumento/${siglaEmpresa}/NTF`, requestCfg);
}
